/**
 * Gets unique key for v-fors
 * @param {object} opts
 * @param {string} opts.prepend
 * @param {string} opts.index
 * @param {string} opts.defaultValue
 * @returns {string}
 */
const uniqueKey = opts => {
  if (!opts || !opts.prepend || !opts.index) {
    return opts?.defaultValue ?? `key-${Date.now()}`;
  }
  return opts.prepend + Date.now() + opts.index;
};

export default uniqueKey;
