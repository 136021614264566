<template>
  <main
    class="ic-sex-offenders"
    :class="{ 'ic-sex-offenders--has-address': addressObj }"
  >
    <h1 class="ic-sex-offenders__title">Sex Offender Watch</h1>
    <p class="ic-sex-offenders__subtitle">
      Our database includes, but is not limited to, full names, physical
      descriptions, mugshots, offenses, and dates!
    </p>
    <div v-if="addressObj" class="ic-sex-offenders__content">
      <TzSpinner
        v-if="sexOffenderSearching"
        class="ic-dashboard-home__spinner"
        :is-check="false"
        spinner-size="140"
        :spinner-animation="true"
        spinner-color="#4476ff"
        spinner-stroke="2"
        :spinner-delay="1000"
        :is-starting-spin="true"
      />
      <div
        class="ic-sex-offenders__section"
        v-if="sexOffenders.length && !sexOffenderSearching"
      >
        <h2 class="ic-sex-offenders__section-title">Watched Address</h2>
        <button
          class="ic-sex-offenders__section-button"
          v-touch:tap="handleUpdateAddressClick"
        >
          Update Address
        </button>
        <div class="ic-sex-offenders__section-content">
          <p class="ic-sex-offenders__section-address" v-html="addressTitle" />
          <TzMap
            :key="mapAddress"
            :access-token="mapbox.access_token"
            :location="mapAddress"
            :static-image="mapbox.static_image"
            :hide-button="true"
            :show-map="true"
          />
        </div>
      </div>
      <WlCollapsibleContent
        v-if="sexOffenders.length && !sexOffenderSearching"
        v-for="(item, index) in sexOffenders"
        :index="index"
        :key="uniqueKey({ prepend: 'sex-offenders_', index })"
        :first-column-title="item.firstColumnTitle"
        :first-column-text="item.firstColumnText"
        :second-column-title="item.secondColumnTitle"
        :second-column-text="item.secondColumnText"
        :cta-text="item.ctaText"
        :header-image="item.headerImage"
        @wl-collapsible-content-cta-click="handleButtonClick(item.link)"
      >
        <div v-if="item.content" class="card-content">
          <div
            class="card-content__section"
            v-for="(section, index) in item.content"
            :key="uniqueKey('sex-offenders-content', index)"
          >
            <TzMarkdown class="card-content__title" :content="section.title" />
            <div class="card-content__details">
              <dl
                class="card-content__detail"
                v-for="(content, index) in section.content"
                :key="index"
              >
                <dt class="card-content__label">
                  {{ content.label }}
                </dt>
                <dd class="card-content__text">
                  {{ content.text }}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </WlCollapsibleContent>
      <div
        class="ic-sex-offenders__no-results"
        v-if="!sexOffenderSearching && !sexOffenders.length"
      >
        <p class="ic-sex-offenders__no-results-title">No Records Found</p>
        <p class="ic-sex-offenders__no-results-subtitle">
          Our comprehensive search of sex offender information for your address
          yielded no results
        </p>
        <button
          class="ic-sex-offenders__section-button ic-sex-offenders__section-button--no-results"
          v-touch:tap="handleUpdateAddressClick"
        >
          Update Address
        </button>
      </div>
    </div>
    <div class="ic-sex-offenders__no-address-view" v-else>
      <div class="ic-sex-offenders__form-wrapper">
        <WlAddressForm
          @address-submit="submitAddress"
          title="Enter your home address to find Sex Offenders near you!"
          :initialValues="addressFormValues"
          :formErrorMsg="errorMsg"
        />
      </div>
    </div>
  </main>
</template>

<script>
import WlAddressForm from '@/components/shared/wl-address-form';
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
import TzSpinner from '@trazi/tz-spinner/src/tz-spinner.vue';
import WlCardPiece from '@/components/shared/wl-card-piece';
import WlCollapsibleCard from '@/components/shared/wl-collapsible-card';
import WlCollapsibleContent from '@/components/shared/wl-collapsible-content';
import WlSectionSlot from '@/components/shared/wl-section-slot';
import WlReportItem from '@/components/shared/wl-report-item';
import setCurrentStreet from '@/assets/js/shared/helpers/setCurrentStreet';
import accountHelper from '@/assets/js/shared/accountHelper';
import uniqueKey from '@/assets/js/shared/helpers/uniqueKey';
import setTitleCase from '@/assets/js/shared/helpers/setTitleCase';
import getSexOffendersData from '@/assets/js/helpers/getSexOffendersData';
import formatAddressWithStreet from '@/assets/js/shared/helpers/formatAddressWithStreet';
import getFormattedMultilineAddressOrUnknown from '@/assets/js/shared/helpers/getFormattedMultilineAddressOrUnknown';
import formatStreetOnly from '@/assets/js/shared/helpers/formatStreetOnly';

const cms = {
  page: require(`@/assets/cms/pages/dashboard-report.json`)
};
const { mapbox: mapbox } = cms.page;
export default {
  name: 'dashboard-sex-offenders',
  layout: 'dashboard',
  components: {
    WlAddressForm,
    WlCollapsibleCard,
    TzMarkdown,
    WlCardPiece,
    WlCollapsibleContent,
    WlSectionSlot,
    WlReportItem,
    TzSpinner,
    TzMap: () => import('@trazi/tz-map/src/tz-map.vue')
  },
  data() {
    return {
      addressObj: null,
      isUpdateError: false,
      sexOffenderSearching: true,
      uniqueKey,
      sexOffenders: [],
      mapbox,
      mapAddress: null,
      addressTitle: '',
      addressFormValues: {
        street: '',
        city: '',
        state: '',
        zip: ''
      }
    };
  },
  computed: {
    errorMsg() {
      return this.isUpdateError
        ? 'There was an error updating your address'
        : '';
    }
  },
  methods: {
    async submitAddress(addressObj) {
      this.sexOffenderSearching = true;
      this.addressObj = await accountHelper.updateAddress({
        addressObj,
        travellingSdk: this.$travellingSDK,
        vm: this
      });
      if (!this.addressObj) {
        this.isUpdateError = true;
      }
      // update user data to track 'set your address' - 'sya' if not already
      await this.$parent.$parent.persistVisitedStep('sya');
      await this.$parent.$parent.refetchCurrentUser();
      this.mapAddress = formatAddressWithStreet({
        address: this.addressObj
      }).replace('<br/>', ', ');
      this.addressTitle = getFormattedMultilineAddressOrUnknown({
        address: this.addressObj
      });

      try {
        await this.setSexOffenders();
      } catch (err) {
        console.error(err);
        this.$bugsnag?.notify(err);
        this.isUpdateError = true;
      }
    },
    async setSexOffenders() {
      this.sexOffenders = await getSexOffendersData(
        {
          account: this.userId,
          street: formatStreetOnly({ address: this.addressObj }) || 'unknown',
          zip: this.addressObj.zip,
          unknownText: 'Unknown',
          nbarsLink: `/dashboard/search?aid=25&firstName={{firstName}}&lastName={{lastName}}&city={{city}}&state={{state}}`
        },
        this.$bugsnag
      );
      this.sexOffenderSearching = false;
    },
    handleButtonClick(url) {
      return this.$parent.$parent.hasPeoplePermission
        ? window.open(url, '_self')
        : this.$parent.$parent.handleNoPeopleSearchAccess(
            '/dashboard/sex-offenders/'
          );
    },
    handleUpdateAddressClick() {
      this.addressFormValues = Object.assign(
        { street: formatStreetOnly({ address: this.addressObj }) },
        this.addressObj
      );
      this.addressObj = null;
    }
  },
  async mounted() {
    if (!this.$parent.$parent.hasOffenderPermission) {
      return await this.$router.push('/dashboard/reactivate/');
    }
    this.user = this.$authHelper.getStoredCurrentUser();
    this.userId = this.user.id;
    const opts = {
      person: {
        addresses: [this.user]
      },
      noComma: true
    };
    const street = setCurrentStreet(opts);
    if (
      street !== '' &&
      this.user.state?.length &&
      this.user.city?.length &&
      this.user.zip?.length
    ) {
      this.addressObj = {
        street,
        state: this.user.state.toUpperCase(),
        city: setTitleCase({ text: this.user.city }),
        zip: this.user.zip
      };
      this.mapAddress = formatAddressWithStreet({ address: this.user }).replace(
        '<br/>',
        ', '
      );
      this.addressTitle = getFormattedMultilineAddressOrUnknown({
        address: this.user
      });
      await this.setSexOffenders();
    }
  },
  head() {
    return {
      title: 'Dashboard - Sex Offenders Watch'
    };
  }
};
</script>
