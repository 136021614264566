import formatNameCaseWithSplitterAndSpacer from './formatNameCaseWithSplitterAndSpacer';
/**
 * Formats a string of current street address with street number, name, type, and affix
 * @param {object} opts
 * @param {object} opts.person
 * @param {boolean} opts.noComma
 * @returns {string|*}
 */
const setCurrentStreet = (opts = {}) => {
  if (
    !opts ||
    !opts.person ||
    !opts.person.addresses ||
    !opts.person.addresses.length
  ) {
    return '';
  }

  let baseAddress = opts.person;
  let streetAddress = '';

  if (!opts.person.current_address && opts.person.addresses.length) {
    baseAddress =
      opts.person.addresses.find(address => address.current_address) ||
      opts.person.addresses[0];
  }

  if (!baseAddress.street_number) {
    return streetAddress;
  }

  streetAddress += baseAddress.street_number;

  if (baseAddress.street_name) {
    streetAddress += ' ' + baseAddress.street_name;
  }

  if (baseAddress.street_type) {
    streetAddress += ' ' + baseAddress.street_type;
  }

  if (baseAddress.street_affix) {
    streetAddress += ' ' + baseAddress.street_affix;
  }

  streetAddress = formatNameCaseWithSplitterAndSpacer({
    name: streetAddress + (opts.noComma ? '' : ',')
  });

  return streetAddress;
};

export default setCurrentStreet;
